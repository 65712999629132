<template>
  <div>
    <el-card shadow="never">
      <el-form label-position="right"
               :model="formData"
               ref="feedbackForm"
               label-width="105px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-row>
          <el-col :span="12">
            <el-form-item label="公司名称：" prop="title">
              {{formData.companyName}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态：">
              {{formData.status | caseStatus}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="作品名称：" prop="title">
              {{formData.opusName}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作品类型：" prop="type">
              {{this.formData.type}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="联系人：" prop="publisher">
              {{formData.contact}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人手机号：" prop="orderNo">
              {{formData.mobile}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="拍摄许可证">
              <img class="img" @click="showImg(formData.certificate, '拍摄许可证')" :src="formData.certificate" alt="">
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公示表文件">
              <img class="img" @click="showImg(formData.publicity, '公示表文件')" :src="formData.publicity" alt="">
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-button v-if="isNewStatus" type="primary" @click="checkSuccess">审核通过</el-button>
          <el-button v-if="isNewStatus" @click="checkFail">审核不通过</el-button>
          <el-button @click="backToList">返回列表</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {execute} from "./option";
export default {
  name: "supportRecordDetail",
  data() {
    return {
      id: null,
      formData: {}
    }
  },
  computed: {
    isNewStatus() {
      return this.formData.status === 'NEW'
    }
  },
  created() {
    this.id = this.$route.params.supportRecordId
    this.tryGetPolicyById()
  },
  methods: {
    ...mapActions(['getSupportRecordById', 'updateSupportRecord']),
    tryGetPolicyById() {
      this.getSupportRecordById(this.id).then(res => {
        console.log(res)
        this.formData = res
      })
    },
    showImg(imgUrl, title) {
      execute('showImg', {
        imgUrl,
        title
      })
    },
    checkSuccess() {
      this.$confirm('确定通过当前扶持的审核吗？').then(() => {
        this.tryUpdateStatus('SUCCESS')
      })
    },
    checkFail() {
      this.$confirm('确定不通过当前扶持的审核吗？').then(() => {
        this.tryUpdateStatus('FAIL')
      })
    },
    tryUpdateStatus(status) {
      this.formData.status = status
      this.updateSupportRecord(this.formData).then(() => {
        this.$message.success('操作成功')
        this.tryGetPolicyById()
      })
    },
    backToList() {
      this.Event.$emit('closeTab')
      this.$router.push({
        name: 'SupportRecordList'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.areaSplit {
  border-top: 1px solid #909399;
  padding-top: 15px;
  max-height: 450px;
  .el-col{
    //width: 100%;
    height: 100%;
    .img{
      width: 100%;
      height: 100%;
      max-height: 400px;
      cursor: pointer;
    }
  }
}
</style>